import { useNavigate } from "react-router-dom";
import { useContext, useState,  } from "react";
import { UserContext } from "../../UserContext";
import axios from "axios";

const Login = () => {
  const [userCredential, setuserCredential] = useState({'username': "gregory.krynski@gmail.com", 'password': "1234567"});
  const { context, setContent } = useContext(UserContext);
  
  let navigate = useNavigate();

  const updateCredential = (e) => {
    setuserCredential((prevState) => ({ 
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const updateContext = (value, user) => {
    setContent(prevContext=>({...prevContext, auth: value, user: user}))
  }

  const login = () => {
    if(userCredential.username !== null && userCredential.username !== "" && userCredential.username.length >= 4){
        if(userCredential.password !== null && userCredential.password !== ""){
                    axios.post('http://localhost:4000/login',{}
                      ,
                    {
                      auth: {
                        username: userCredential.username,
                        password: userCredential.password,
                      }
                    }
                    )
                    .then( (response) => {
                        updateContext({
                          username: userCredential.username,
                          password: userCredential.password,
                        }, response.data);
                        navigate(`/`)
                    })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
  }

  return (
    <div className="content-p" style={{ height: "100%" }}>
      <div className="flex-grid-container mx-auto">
        <div className="login-item1">
          <div className="d-flex col" >
            <h3>LOGIN FORM</h3>
            <p>Fill Up Details Below</p>
            <input
              type="text"
              placeholder="&nbsp;&nbsp;USERNAME OR EMAIL"
              className="input-text w-100 wm-600px "
              id="username"
              value={userCredential.username}
              onChange={updateCredential}
            />
            <input
              type="password"
              placeholder="&nbsp;&nbsp;PASWORD"
              className="input-text w-100 wm-600px "
              id="password"
              value={userCredential.password}
              onChange={updateCredential}
            />
            <btn className="btn" onClick={login}>
              LOGIN
            </btn>
          </div>
        </div>
        <div className="login-item2">
          <div className="d-flex col">
            <h3>LOGIN FORM</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </p>
            <a className="btn" onClick={() => navigate(`/register`)}>
            Register</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
