import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";

const Lessons = () => {
  const navigate = useNavigate();
  const { context, setContent } = useContext(UserContext);
  
  const [lessonData, setLessonData] = useState([])
  const [videoUrl, setVideoUrl] = useState("");
  const videoList = () => {
    axios
      .get(
        "http://localhost:4000/lessons",
        {}
        // {
        //   auth: {
        //     username: userCredential.username,
        //     password: userCredential.password,
        //   },
        // }
      )
      .then((response) => {
        setLessonData(response.data);
        if(response.data.length > 0){
          loadVideo(response.data[0].id)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const loadVideo = (value) => {
    axios
      .get(
        `http://localhost:4000/videos/${value}`,
        {}
        // {
        //   auth: {
        //     username: userCredential.username,
        //     password: userCredential.password,
        //   },
        // }
      )
      .then((response) => {
        console.log(response);
        setVideoUrl(response.data.url);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if(!context.auth){
        navigate(`/login`);
      }
    videoList();
  }, []);

  return (
    <div className="h-100 big-flex-grid-container bg-white">
      <div className="lesson-menu-container h-100 item1">
        <div className="bg-grey h-100" >
          <ul className="lesson-menu mr-auto ml-auto h-content">
          <h3 style={{fontSize: "21px !important"}}>
            KPOP STAR PROJECT
          </h3>
            {lessonData.map(data => {
              return(
                <li className="lesson-link">
                  <div className="w-content" onClick={() => loadVideo(data.id)}>
                   { data.name}
                  </div>
                </li>
              )
            })
            }
          </ul>
        </div>
      </div>
      <div className="w-100 d-s-content-p item2">
        <div className="h-100">
          <video className="w-100 h-100" src={videoUrl} controls={true} />
        </div>
      </div>
    </div>
  );
};

export default Lessons;
