import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";
import prefectureData from "../../prefecture.json";

const ApplicationForm = () => {
  const navigate = useNavigate();
  const { context, setContent } = useContext(UserContext);
  const [state, setState] = useState({
    prefecture: "tokyo",
    birthday: "1993-07-16",
    id: context.user.id
  });
  const prefectureList = Object.values(prefectureData);

  const updateState = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const createUser = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost:4000/applicants", state, {
        // auth: {
        //   username: context.auth.username,
        //   password: context.auth.password,
        // },
      })
      .then(function (response) {
        navigate(`/${encodeURI('payment')}`)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(()=>{
    if(!context.auth){
      navigate(`/login`);
    }
    console.log(context)
    if(context.user.applicantId !== null){
      navigate(`/payment`)
    }
  },[])

  return (
    <div className="flex-grid-container mx-auto vh-100 content-p">
      <div className="w-100"><h2 className="text-center">申し込みフォーム</h2>
      <form className="col w-100 mx-auto content-p-b">
        <div className="w-100 row">
          <input
            required
            className="w-50 input-text"
            type="text"
            placeholder="名前ふりがな"
            id="furiganaFirstName"
            value={state.furiganaFirstName}
            onChange={updateState}
          />
          <input
            required
            className="w-50 input-text"
            type="text"
            placeholder="苗字ふりがな"
            id="furiganaLastName"
            value={state.furiganaLastName}
            onChange={updateState}
          />
        </div>
        <div className="w-100 row">
          <input
            required
            className="w-50 input-text"
            type="text"
            placeholder="名前"
            id="firstName"
            value={state.firstName}
            onChange={updateState}
          />
          <input
            required
            className="w-50 input-text"
            type="text"
            placeholder="苗字"
            id="lastName"
            value={state.lastName}
            onChange={updateState}
          />
        </div>
        <input
          className="input-text"
          type="text"
          placeholder="メール"
          id="email"
          value={state.email}
          onChange={updateState}
        />
        <input
          className="input-text"
          type="text"
          placeholder="電話番号"
          id="contactNumber"
          value={state.contactNumber}
          onChange={updateState}
        />
        <select
          className="input-text"
          type="text"
          placeholder="県"
          id="prefecture"
          value={state.prefecture}
          onChange={updateState}
        >
          {prefectureList.map((data) => {
            return <option value={data.v}>{data.ja_text}</option>;
          })}
        </select>
        <input
          className="input-text"
          type="text"
          placeholder="市"
          id="city"
          value={state.city}
          onChange={updateState}
        />
        <input type="date" className="input-text"/>
        <input          
          className="input-text"
          type="number"
          placeholder="重さ"
          id="weight"
          value={state.weight}
          onChange={updateState}
        />
        <input
          className="input-text"
          type="number"
          placeholder="高さ"
          id="height"
          value={state.height}
          onChange={updateState}
        />
        <input
          className="input-text"
          type="text"
          placeholder="好きな歌手"
          id="favouriteSinger"
          value={state.favouriteSinger}
          onChange={updateState}
        />
        <input
          className="input-text"
          type="text"
          placeholder="好きなダンサー"
          id="favouriteDancer"
          value={state.favouriteDancer}
          onChange={updateState}
        />
        <input type="hidden" value={context.user.id} id="userId"/>
        <br />
        <div
          className="mx-auto"
          style={{
            cursor: "pointer",
            backgroundColor: "white",
            color: "black",
            fontSize: "1.5rem",
            width: "190px",
            height: "50px",
            borderRadius: "5px",
          }}
        >
          <div className="center-div w-content" onClick={createUser}>
            submit
          </div>
        </div>
      </form>
      </div>
    </div>
  );
};

export default ApplicationForm;
