import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { useNavigate } from "react-router-dom";

const HamburgerButton = (props) => {
    const navigate = useNavigate();
    const {children} = props;
    const { context, setContent } = useContext(UserContext);
    const [display, setDisplay] = useState(false);

const clearSession = () => {
  setContent({auth: null});
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  navigate("/")
}

  return (
    <div className="hamburger-btn-container">
      <div className="hamburger-btn" onClick={()=> setDisplay(!display)}>
        <div className="white-bar"></div>
        <div className="white-bar"></div>
        <div className="white-bar"></div>
      </div>
      <div onClick={()=> setDisplay(!display)} className={`${display ? 'hamburger-display-flex' : 'hamburger-display-none' } `}>
          {children}
      </div>
      <a className="Logo w-100" href="/">K POP STAR PROJECT</a>
      {
        context.auth ? (<a onClick={clearSession} className="login-btn">LOGOUT</a>) : (<a href="/login" className="login-btn">LOGIN</a>)
      }
    </div>
  );
};

export default HamburgerButton;
