import parse from "html-react-parser";
import worldWide from "../../assets/img/worldwide-768x542.jpg";
import virg from "../../assets/img/virg-724x1024.jpg";
import sia from "../../assets/img/SiA.jpg";
import lasie from "../../assets/img/LAsie.jpg";
import instagram from "../../assets/img/Instagram.jpg";

const Support = () => {
  const support = [
    {
      title: `主催<br/>DME WORLD`,
      text: `2013年設立。東京港区にオフィスを構え、六本木ヒルズ内に自社スタジオ、2019年ニューヨーク支店オープン。現在ソウル支店建設中
                    Dance/Music/Entertainmentの３つを軸に世界を舞台に幅広いエンタメ事業を展開する国際派エージェンシー。
                    アーティスト/ダンサー育成、オーディション、ワークショップは韓国を中心に、海外アーティストツアーはアメリカを中心に、日本で一番海外の芸能界と強いパイプを持つことで有名。
                    最近では韓国の元大人気グループ2NE1と合同企画で才能あるダンサーを世界へ送り出したり、その他数々のK-POP関連オーディションで夢を持つ若者をサポートする実績を多数持つ。`,
      image: worldWide,
    },
    {
      title: "パートナー<br/>VIRG's Celebrity<br/>Dance Studio",
      text: `日本、韓国、アメリカを中心に世界的スターたちと数々の仕事をこなす総合エンターテイメントプロデューサー、DME WORLD代表取締役。
                    BTS、TWICE、BLACKINK等のツアーバックダンサーを日本へ招聘、ダンスワークショップ、オーディションを開催。合格者は韓国行きの切符を手にした。
                    マイケルジャクソン、マライアキャリー、クリスブラウン、BIGBANG、2NE1、EXILE、三浦大知、AI、倖田來未、嵐、SMAP、シェネル、他多数の有名アーティストの振り付け、舞台監督、共演等幅広く活躍。`,
      image: virg,
    },
    {
      title: "サポートスクール<br/><br/>SiA VOCAL & DANCE SCHOOL",
      text: `京都  河原町校
                初心者でも安心！
                K-POP振り付けレッスンあり。
                BTS、EXOバックダンサー経験者在籍。
                楽しく踊りたい方やアーティストを目指す方にもお勧めのヴォーカル＆ダンススクール。
                卒業生も芸能界で活躍中。
                17LIVE株式会社とパートナー契約。`,
      image: sia,
    },
    {
      title: "サポートスクール<br/><br/>L'Asie entertainment school",
      text: `沖縄市高原にある県内最大級のK-POPダンススクール。
                充実のレッスン内容で３歳から大人までダンスの基礎もバッチリ習得しながらK-POPダンスを楽しく学べます。
                見学、無料体験レッスンも可能。
                お問い合わせは公式LINEにて。`,
      image: lasie,
    },
    {
      title: "サポートSNS",
      text: `1 million dance studio_japan
            KOREA AUDITION
            New_Star_Audition 
            Super Kpop Land
            Millennium Dance Complex Tokyo
            VCDS_Workshop
            韓国デビューオーディション
            `,
      image: instagram,
    },
  ];

  return (
    <div>
      <div className="flex-grid-container mx-auto wm-1140px"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#000",
        }}
      >
        <h3>K-POP STAR PROJECT サポーター</h3>
      </div>
      <div className="flex-grid-container mx-auto wm-1140px">
        <div className="array-list">
          {support.map((data) => {
            return (
              <div className="support-item">
                <div className="array-img-container">
                  <img className="array-img" src={data.image} alt="image" />
                </div>
                <h3>{parse(data.title)}</h3>
                <div>
                  <p>{data.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Support;
