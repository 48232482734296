import VISA from "../../assets/img/visa.jpg";
import MASTERCARD from "../../assets/img/mastercard.png";
import DINER from "../../assets/img/diners-club-logo.jpg";
import DISCOVER from "../../assets/img/discover.png";
import JCB from "../../assets/img/JCB.png";
import GPAY from "../../assets/img/gpay.jpg";

import JPBANK from "../../assets/img/japanpostal.jpeg";
import MIZUHO from "../../assets/img/mizuho.jpg"
import MUFG from "../../assets/img/mufg.jpg"
import SMBC from "../../assets/img/smbc.png"
import SHINSEI from "../../assets/img/shinsei.png"
import RESONA from "../../assets/img/resona.jpeg"

import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { UserContext } from "../../UserContext";


const Payment = () => {
    const navigate = useNavigate();
    const { context, setContent } = useContext(UserContext);
    const [bankInfo, setBankInfo ] = useState(false);

    const bankPay = () => {
    }

    useEffect(()=>{
        if(!context.auth){
            navigate(`/login`);
          }
    },[])


    return (
        <div className="content-p flex-grid-container mx-auto row vh-70">
            {bankInfo && <div className="backdrop h-100" style={{position: "absolute", top: "0", left: "0"}}/> }
           {bankInfo && ( <div className="abs-center-div rounded bg-white" style={{padding: "10px",width: "300px", height: "70vh"}}>
             <div className="ml-auto w-content" style={{fontSize: "21px", color: "black"}} onClick={()=>setBankInfo(!bankInfo)}>X</div>
             <p style={{color: "black"}}> 
                <h4>
                Please pay using wire transfer using the following Data
                Make sure to add Payment Id in transfer details
                </h4>
                <table className="payment-table">
                    <tr className="payment-table-row">
                        <th className="payment-table-cell">Bank name</th>
                        <td className="payment-table-cell">DME WorldWide</td>
                    </tr>
                    <tr style={{borderBottom: "1px solid black"}}>
                        <th>Bank Address</th>
                        <td>
                            <div className="payment-table-cell">
                            2-2-15 WinAoyama 942,<br/>
                            Minami Aoyama,<br/>
                            Minato-ku, Tokyo,<br/>
                            106-0032 Japan
                            </div>
                        </td>
                    </tr>
                    <tr className="payment-table-row">
                        <th className="">Branch name</th>
                        <td>
                            <div className="payment-table-cell">
               
                            </div>
                          </td>
                    </tr>
                    <tr className="payment-table-row">
                        <th>Branch number</th>
                        <td>
                            <div className="payment-table-cell">
               
                            </div>
                          </td>
                    </tr>
                    <tr className="payment-table-row">
                        <th className="payment-table-cell">Account number</th>
                        <td>
                            <div className="payment-table-cell">
                 
                            </div>
                          </td>
                    </tr>
                    <tr className="payment-table-row">
                        <th className="payment-table-cell">
                            Payment Id</th>
                        <td>
                            <div className="payment-table-cell">
                            132-444-666
                            </div>
                          </td>
                    </tr>
                </table>
             </p>
            </div>)
            }
            <form className="content-p col ml-auto m-mr-auto" id="stripeForm" method="post" action="http://localhost:4000/payments/start">
                <table className="mt-auto bg-white rounded p-10">
                    <tr className="w-100">
                    <td className="payment-image-container">
                            <img src={VISA} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img src={MASTERCARD} className="payment-image"/>
                        </td>
                    </tr>
                    <tr>
                    <td className="payment-image-container">
                            <img src={DINER} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img style={{objectFit: "cover"}} src={DISCOVER} className="payment-image"/>
                        </td>
                    </tr>
                    <tr>
                    <td className="payment-image-container">
                            <img style={{objectFit: "fit"}} src={JCB} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img style={{objectFit: "fit", width: "100%"}} src={GPAY} />
                        </td>
                    </tr>
                </table>
                {/* <input name="videoId" placeholder="Video ID" /> */}
                <br/>
                <div className="btn mx-auto mb-auto" onClick={()=>document.getElementById('stripeForm').submit()}>支払い</div>
            </form>
            <form className="content-p col m-ml-auto mr-auto">

            <table className="mt-auto bg-white rounded p-10">
                    <tr className="w-100">
                      <td className="payment-image-container">
                            <img src={JPBANK} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img src={MIZUHO} className="payment-image"/>
                        </td>
                    </tr>
                    <tr>
                    <td className="payment-image-container">
                            <img style={{objectFit: "cover"}} src={MUFG} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img style={{objectFit: "fit", width: "100%"}} src={SMBC} />
                        </td>
                    </tr>
                    <tr>
                        <td className="payment-image-container">
                            <img src={SHINSEI} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img src={RESONA} className="payment-image"/>
                        </td>
                    </tr>
                </table>
                <input type="hidden" value="3" name="userId"/>
                <br/>
                <div className="btn mx-auto mb-auto" onClick={()=>setBankInfo(!bankInfo)}>支払い</div>
            </form>
            </div>
    )
}

export default Payment;