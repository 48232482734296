import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import HamburgerButton from "../HamburgerButton";
import { UserContext } from "../../UserContext";

const Menu = () => {
  const { context, setContent } = useContext(UserContext);
  let navigate = useNavigate();

  let options = [
    { url: "", text: "Welcome" },
    { url: "ホーム", text: "ホーム" },
    { url: "練習生留学", text: "練習生留学" },
    { url: "お申し込み", text: "お申し込み" },
    { url: "Q&A", text: "Q&A" },
    { url: "サポーター", text: "サポーター" },
    { url: "プレゼント", text: "プレゼント" },
    { url: "お問い合わせ", text: "お問い合わせ" }, 
    { url: "レッスン", text: "レッスン" }
  ];

  const loginPages = ["レッスン", "申請者"];

  const setTopPage = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  return (
    <div className="menu-container">
      <HamburgerButton >
      <ul className="nav-menu">
        {options.map((option) => {
          if(context.auth && loginPages.includes(option.url) || !loginPages.includes(option.url)){
            return (
              <li className="nav-link" onClick={() => {
                  setTopPage();
                  navigate(`/${option.url}`);
                  }}>
                {option.text}
              </li>
            );
          }
        })}
      </ul>
      </HamburgerButton >
    </div>
  );
};

export default Menu;
