import BlOOM from "../../assets/img/bloom.jpg";
import FAMILY from "../../assets/img/FAMILY.jpg";
import WELCOMEBANNER from "../../assets/img/welcome-banner.jpg";

const Welcome = () => {
  return (
    <div>
      <div className="big-content mx-auto col d-content-p w-content vh-90">
        <img className="splash-screen-img content-p"
          src={WELCOMEBANNER}
        />
        <div className="splash-screen-text mb-auto">
        <h3>はじめに</h3>

        <p className="text-center">
          当プロジェクト主催DME WORLDはDance/Music/Entertainmentを軸に、<br/>
          日本と海外のエンターテイメントの架け橋として長年に渡り数多くの実績を残し、近年で
          は特に人気のK-POPに力を入れております。
          <br/>
          日本の才能を世界に！
        </p>
        </div>
      </div>
      <div className="grid-container">
        <div className="item1 content-p bg-pink">
          <h2>K-POP STAR PROJECT</h2>
          <h2>について</h2>
          <p>
            当プロジェクトは韓国デビューを目指す
            <br/>
            若く才能溢れる方を夢の実現に向けて 
            <br/>
            強力プッシュする
            “夢実現プロジェクト”です。
          </p>
          <p>
            受講生の中から成績優秀者に選ばれた方々を当プロジェクトが留学費用を負担し、韓国留学へ無料ご招待。
            <br/>
            現地で練習生になれるようあらゆるサポートを受けられます。
            <br/>
            韓国では最短で練習生になれるよう現地サポートチームが全力でお手伝いしますが、現地オーディションではご本人の実力次第で合否が決まるため、韓国へ行けば必ず練習生になれると保証するものではありません。
            <br/>
            当プロジェクトでは練習生になるために最も有利な環境、日本にいては得られない夢実現のビッグチャンスをご提供することをお約束します。
          </p>
        </div>
        <div className="item2">
          <img className="w-100"
            src={BlOOM}
          />
        </div>
        <div className="item3">
          <img className="w-100"
            src={FAMILY}
          />
        </div>
        <div className="item4 content-p">
          <h2>保護者の方へ</h2>
          <p>
            当プロジェクトで成績優秀者になり韓国留学へ行ける事になった場合、嬉しさの反面、大事なお子様を海外へ送る事は大きな不安があることと思います。そもそも韓国で活躍したいというお子様の夢を認め、応援する事自体が難しいという保護者の方も多い中、お子様を信じ、夢に向かい挑戦する姿を見守っておられる事、本当に素晴らしい事だと思います。
          </p>

          <p>
            受講者から送られてくる夢に対する熱い想いを私共も真剣に受け止め、その夢を叶えるためのサポートを全力で行なって参ります。
            無限の可能性を秘めた若い受講者の皆様とその保護者の方々を第一に大切に考えておりますので、どうぞご安心してお子様の夢への挑戦を見守ってあげて下さい。
            <br/>
            何かご不明点等ありましたらお気軽にお問い合わせ下さい。
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
