import parse from "html-react-parser";
import img1 from "../../assets/img/kpop-1.jpg";
import img2 from "../../assets/img/kpop-2.jpg";
import img3 from "../../assets/img/kpop-3.jpg";
import img4 from "../../assets/img/kpop-4.jpg";
import img5 from "../../assets/img/kpop-5.jpg";
import img6 from "../../assets/img/Pre-image.jpg";
const Present = () => {
  const pastEvents = [
    {
      text: `元2NE1 MINZY & VIRG<br/>
      オンラインダンスコンテスト ワールドワイド`,
      image: img1,
    },
    {
      text: `元YGダンサーCRAZY mai<br/>
      K-POPワークショップ&オーディション`,
      image: img2,
    },
    {
      text: `VIRG / MIWA /YORITO<br/>
      UPLOAD アーティストバックダンサー ワークショップ&オーディション`,
      image: img3,
    },
    {
      text: `BIGBANG × Ciara ジャパンツアー VIRG振付けアーティストバックダンサーオーディション`,
      image: img4,
    },
    {
        text: `K-POPアイドル/ダンサー<br/>
        韓国留学ワークショップ&オーディション`,
        image: img5,
      },
  ];

  return (
    <div>
      <div className="big-flex-grid-container vh-100">
        <div className="item1 d-w-50">
          <img
            className="w-100 h-100"
             src={img6}
          />
        </div>
        <div className="item2 content-p d-w-50">
          <div className="center-div">
          <h2>参加者全員プレゼント</h2>
          <div>
            <p>
              受講終了後のレベルチェックまで受けた方
              <br />
              全員に５万円分のクーポンをプレゼント！
              <br />
            </p>
            <p>
              当プロジェクト主催 DME
              WORLDでは多数のK-POP関連オーディション、イベント、ワークショップ等開催しております。
              <br />
              次回以降、当プロジェクト主催の全ての企画にお使い頂けるクーポンになります。
            </p>
          </div>
          </div>
        </div>
      </div>
      <div className="content col"
      >
        <div className="section-past-events">        
            <h2 className="content-p-l">過去の当主催K-POP関連<br/>
            イベント開催例 (一部)</h2>
        <div className="past-events">
          {pastEvents.map((data) => {
            return (
              <div className="past-event-item">
                <img src={data.image} alt="image" className="w-100" />
                <div>
                  <p>{parse(data.text)}</p>
                </div>
              </div>
            );
          })}
        </div>
        </div>
      </div>
    </div>
  );
};

export default Present;
