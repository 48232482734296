import "./App.css";

import { UserContext } from "./UserContext";
import { useState } from "react";
import { BrowserRouter as Router  } from "react-router-dom";
import PageManagement from "./Componets/PageManagement";

function App() {
  const loginPages = ["レッスン", "申請者"];
  const [context, setContent] = useState({auth: null, appStatus: 0});


  return (
    <div className="h-body">
      <UserContext.Provider value={{context, setContent}}>
      <Router>
        <PageManagement/>
      </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
